import React from "react";
import Layout from "../components/layout/Layout";
import Hero from "../components/modules/Hero";
import ContactForm from "../components/modules/ContactForm";

const contact = () => {
  return (
    <Layout title="Contact" showCTA={false} isHome={true}>
      <Hero
        title="Want to get in touch?"
        subText="If you have any questions, enter your details in the form below and we'll be in touch."
        color="black"
        showContactCTA={false}
      />
      <div className="w-full md:w-2/5 py-16 px-12">
        <ContactForm />
      </div>
    </Layout>
  );
};

export default contact;
