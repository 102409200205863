import React from "react";
import {Button} from "../ui/Button";

const ContactForm = ({ quote = false }) => {
  return (
    <section className="bg-white">
      <div className="max-width">
        {
          quote ? <h2 className="text-3xl md:text-6xl mb-5">Want to work with us?</h2> : 
          <h2 className="text-3xl md:text-6xl mb-5">Want to talk to us?</h2>
        }
        <form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <div className="mb-2 flex flex-col">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              required
              name="name"
              className="rounded px-4 py-3 focus:border-primaryMagenta focus:ring-primaryMagenta"
              placeholder="Enter your name here"
            />
          </div>
          <div className="mb-2 flex flex-col">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              required
              name="email"
              className="rounded px-4 py-3 focus:border-primaryMagenta focus:ring-primaryMagenta"
              placeholder="Enter your email here"
            />
          </div>
          {
            quote ? <>
              <div className="mb-2 flex flex-col">
                <label htmlFor="project_type">Project Type (App/Web/Other)</label>
                <input
                  type="text"
                  required
                  name="project_type"
                  className="rounded px-4 py-3 focus:border-primaryMagenta focus:ring-primaryMagenta"
                  placeholder="I'm working on an app"
                />
              </div>
            </> : null
          }
          <div className="mb-2 flex flex-col">
            <label htmlFor="message">{quote ? 'Project Overview' : 'Your Message' }</label>
            <textarea
              name="message"
              required
              rows="3"
              className="rounded px-4 py-3 focus:border-primaryMagenta focus:ring-primaryMagenta"
              placeholder="Enter message here"
            ></textarea>
          </div>
          <Button size="small" type="">Submit</Button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
